import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useResource from "../../../hooks/useResource";
import ListItem from "../layout-list-item";
import { useLocation } from "react-router-dom";
import "./style.scss";
import { IAccordionMenuProps } from "../../../interfaces/layout/accordion";

const AccordionMenu: React.FC<IAccordionMenuProps> = ({
  menuItems,
  isCollapse,
  Icon,
  navigateLink,
  expanded,
  onToggle,
}) => {
  const { resourceAllocate } = useResource();
  const location = useLocation();
  const isStateActive = navigateLink.some(
    (link) =>
      location.pathname === link ||
      location.pathname.split("/")[1] === link.split("/")[1]
  );

  return (
    <Accordion className={`accordion-menu ${isCollapse ? "collapsed" : ""}`}  expanded={expanded} onChange={onToggle}>
<AccordionSummary
   expandIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
   className={`accordion-summary ${isStateActive && !expanded ? "active" : ""} ${expanded ? "Mui-expanded" : ""}`}
>
   <div className="summary-content">
     <Tooltip title={isCollapse ? menuItems.name : ""} placement="right">
       <ListItemIcon className="nav-icon">
         <Icon className={`icon ${isStateActive ? "active" : ""}`} />
       </ListItemIcon>
     </Tooltip>
     {!isCollapse && (
       <ListItemText primary={menuItems.name} className="menu-name" />
     )}
   </div>
</AccordionSummary>
      <AccordionDetails className={isCollapse ? "accordion-details collapsed" : "accordion-details"}>
        <List className="menu-list">
          {menuItems.data.map((item, index) => (
            item &&
            item.resource &&
            item.resource.some((resource) => resourceAllocate(resource)) && (
              <ListItem
                key={index}
                label={item.label}
                navigateLink={item.navigateLink}
                Icon={item.Icon}
                isCollapse={isCollapse}
              />
            )
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMenu;